import classNames from "classnames";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/advancedFormat";
import Hls from "hls.js";
import { useEffect, useRef } from "preact/hooks";

dayjs.extend(customParseFormat);

const HlsPlayer = ({
	playerSrc,
	playerWidth,
	className = "",
}) => {
	const videoRef = useRef(null);
	const timeRef = useRef(null);

	const config = {
		xhrSetup: (xhr) => {
			xhr.withCredentials = true;
		},
	};

	useEffect(() => {
		const hls = new Hls(config);
		hls.attachMedia(videoRef.current);
		hls.loadSource(playerSrc.src);

		hls.on(Hls.Events.ERROR, (event, data) => {
			const { type, details, fatal } = data;
			console.error(type, details);

			if (data.fatal) {
				switch (data.type) {
					case Hls.ErrorTypes.MEDIA_ERROR:
						console.warn("Fatal media error encountered, try to recover");
						hls.recoverMediaError();
						break;
					case Hls.ErrorTypes.NETWORK_ERROR:
						// All retries and media options have been exhausted.
						console.error("Fatal network error encountered", data);
						break;
					default:
						console.error("Unrecoverable error encountered.");
						hls.destroy();
						break;
				}
			}
		});

		hls.on(Hls.Events.FRAG_CHANGED, (_, { frag }) => {
			const segmentDateTime = dayjs(frag.programDateTime);
			timeRef.current.innerHTML = segmentDateTime.format(
				"dddd, MMMM D, YYYY HH:mm:ss",
			);
		});

		return () => {
			if (hls) {
				hls.detachMedia();
				hls.destroy();
			}
		};
	}, [playerSrc]);

	return (
		<div>
			<h1 ref={timeRef} className={classNames(`text-base font-bold mb-2 max-[${playerWidth}px]:ml-4`)}>
				&nbsp;
			</h1>
			<video
				id="player"
				ref={videoRef}
				preload="metadata"
				autoplay
				controls
				muted
				className={className}
				style={{ width: playerWidth, padding: 0 }}
			/>
		</div>
	);
};

export { HlsPlayer };
